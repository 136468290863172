@import '../../styles/customMediaQueries.css';

.tooltip {
  position: relative;
  width: fit-content;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: auto;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 95%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    visibility: visible;
    opacity: 1;
  }
}
